import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from './Model';
import styled from 'styled-components';

// Estilo para o efeito Matrix no fundo
const MatrixBackground = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

// Contêiner para o novo ASCII art centralizado acima do Canvas
const ASCIIContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  font-family: monospace;
  font-size: 16px;
  white-space: pre;
  z-index: 2;
`;

// Estilo para o container centralizado com borda
const CenterContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  border: 2px solid #00ff00;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
`;

// Estilo para o botão de copiar
const CopyButton = styled.button`
  padding: 8px 12px;
  background-color: #00ff00;
  color: #000;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #00cc00;
  }
`;

// Estilo para os ícones X e Telegram, centralizados abaixo do container
const IconsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  z-index: 2;
`;

// Estilo para cada botão de ícone
const IconButton = styled.button`
  padding: 8px 16px;
  background-color: #00ff00;
  color: #000;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #00cc00;
    transform: scale(1.05);
  }
`;

function App() {
  const matrixRef = useRef(null);

  useEffect(() => {
    const canvas = matrixRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const fontSize = 14;
    const columns = canvas.width / fontSize;
    const drops = Array(Math.floor(columns)).fill(1);

    function drawMatrix() {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#00ff00';
      ctx.font = `${fontSize}px monospace`;

      drops.forEach((y, index) => {
        const text = letters[Math.floor(Math.random() * letters.length)];
        const x = index * fontSize;
        ctx.fillText(text, x, y * fontSize);

        if (y * fontSize > canvas.height && Math.random() > 0.975) {
          drops[index] = 0;
        }
        drops[index]++;
      });
    }

    const interval = setInterval(drawMatrix, 50);
    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$");
    alert("Copiado!");
  };

  return (
    <>
      <MatrixBackground ref={matrixRef} />

      {/* Novo ASCII Art centralizado acima do modelo 3D */}
      <ASCIIContainer>
        {`____   ___________________   _________ __________    _____   
\\   \\ /   /\\_   _____/\\   \\ /   /  _  \\\\______   \\  /  _  \\  
 \\   Y   /  |    __)_  \\   Y   /  /_\\  \\|       _/ /  /_\\  \\ 
  \\     /   |        \\  \\     /    |    \\    |   \\/    |    \\
   \\___/   /_______  /   \\___/\\____|__  /____|_  /\\____|__  /
                   \\/                 \\/       \\/         \\/ `}
      </ASCIIContainer>

      <Canvas
        style={{ width: '100vw', height: '100vh', position: 'relative', zIndex: 1 }}
        camera={{ position: [10, -5, 25], fov: 25}}
      >
        <OrbitControls enableZoom={true} minDistance={5} maxDistance={55} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <Model url="/modelo.gltf" scale={[0.25, 0.25, 0.25]} position={[0, -9, 0]} />
      </Canvas>

      <CenterContainer>
        <span>$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$</span>
        <CopyButton onClick={copyToClipboard}>COPY</CopyButton>
      </CenterContainer>

      <IconsContainer>
        <IconButton onClick={() => window.open("https://x.com/1stIBMcomputer", "_blank")}>X</IconButton>
        <IconButton onClick={() => window.open("https://t.me/firstibm", "_blank")}>Telegram</IconButton>
      </IconsContainer>
    </>
  );
}

export default App;
